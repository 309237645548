import React, { useState, useEffect } from "react";

import BotMessage from "./Component/BotMessage";
import UserMessage from "./Component/UserMessage";
import Messages from "./Component/Message";
import Input from "./Component/Input";
import UploadImage from "./Component/uploadImage";
import Snackbar from "@mui/material/Snackbar";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import DeleteIcon from '@mui/icons-material/Delete';

import API from "./ChatBotAPI";
import axios from "axios";

import "./App.css";
import Header from "./Component/Header";
import { backendURL } from "./utils/env";

function App() {
  const [messages, setMessages] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState("");
  const [selectedOption, setSelectedOption] = useState("GPT");
  const [Image, setImage] = useState("");
  const [open, setOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [Loader, setLoader] = useState(false);
  const [files_data, setFiles_data] = useState([]);
  const [selectedfile_name, setSelectedfile_name] = useState("");
  const [MainLoader, setMainLoader] = useState(false);

  useEffect(() => {
    get_file();
  }, []);

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = async () => {
    try {
      setLoader(true);
      const formData = new FormData();
      formData.append("file", selectedFile);

      const res = await axios.post(
        `${backendURL}/upload`,
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data) {
        setOpen(true);
        setMessage(res?.data?.message);
        setLoader(false);
        get_file();
      }
    } catch (error) {
      setMessage(error.response?.data.error);
      setOpen(true);
      setLoader(false);
      console.error("Error uploading file:", error);
    }
  };

  const callingAPi = async (text) => {
    const extension = selectedfile_name.split(".").pop().toLowerCase();
    try {
      if (selectedOption === "GPT") {
        if (extension === "json") {
          const res = await axios.post(
            `${backendURL}//chat_Json_gpt`,
            {
              Question: text,
              file_name: selectedfile_name,
            }
          );
        }
        const res = await axios.post(
          `${backendURL}//chat_gpt`,
          {
            Question: text,
            modelName: selectedOption,
          }
        );

        return res?.data;
      } else if (selectedOption === "mistral") {
        const res = await axios.post(
          `${backendURL}/chat_mistral`,
          {
            Question: text,
            modelName: selectedOption,
          }
        );

        return res?.data;
      } else {
        if (extension === "json") {
          const res = await axios.post(
            `${backendURL}//chat_Json_llama`,
            {
              Question: text,
              file_name: selectedfile_name,
            }
          );
        }
        const res = await axios.post(
          `${backendURL}/chat_llama`,
          {
            Question: text,
            modelName: selectedOption,
          }
        );

        return res?.data;
      }
    } catch (error) {
      setMessage("Please Try Again");
      setOpen(true);
    }
  };

  const get_file = async () => {
    try {
      const response = await axios.get(
        `${backendURL}/get_data`
      );

      setFiles_data(response?.data?.files);
    } catch (error) {
      setMessage(error.response?.data.error);
      setOpen(true);
    }
  };

  const convert_file_into_vector = async (data) => {
    try {
      setMainLoader(true);
      setSelectedfile_name(data);
      const response = await axios.post(
        `${backendURL}/convert_to_vector`,
        {
          file_name: data,
        }
      );
      if (response.status === 200) {
        setMessage("Ready to ask Question");
        setOpen(true);
        setMainLoader(false);
      }

    } catch (error) {
      setMessage(error.response?.data.error);
      setOpen(true);
    }
  };
  const deleteFile = async (data) => {
    try {
      setMainLoader(true);
      // setSelectedfile_name(data);
      const response = await axios.post(
        `${backendURL}/delete-file`,
        {
          filename: data,
        }
      );
      if (response.status === 200) {
        // setMessage("Ready to ask Question");
        // setOpen(true);
        setMainLoader(false);
      }
      await get_file()
    } catch (error) {
      setMessage(error.response?.data.error);
      setOpen(true);
    }
  };

  const send = async (text) => {
    const botMessageLoading = (
      <div className="message-container">
        <div className="bot-message">Loading..</div>
      </div>
    );

    // Update messages state with the new user message and the initial bot message
    setMessages((prevMessages) => [
      ...prevMessages,
      <div key={prevMessages.length} className="message-container">
        <div className="user-message">{text}</div>
      </div>,
      botMessageLoading,
    ]);

    try {
      // Call the API and await its response
      const apiResponse = await callingAPi(text);

      // Construct the new bot message with the API response
      const botMessageResponse = (
        <div className="message-container">
          <div className="bot-message">{apiResponse.message}</div>
        </div>
      );

      // Replace the last element of messages with the bot message containing the API response
      setMessages((prevMessages) => [
        ...prevMessages.slice(0, -1),
        botMessageResponse,
      ]);
    } catch (error) {
      console.error("Error calling API:", error);
      setMessage("please try again");
      setOpen(true);
    }
  };
  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setOpen(false);
  };

  const vertical = "top";
  const horizontal = "right";
  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          gap: "40px",
          height: "85dvh",
          flexWrap: "wrap",
          padding: '20px'
        }}
      >
        <div
          className="backdrop"
          style={{ display: MainLoader ? "block" : "none" }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100%",
              width: "100%",
            }}
          >
            <div className="loader"></div>
          </div>
        </div>
        <div className="chatbot" style={{ width: "60%", height: "100%" }}>
          <Header />
          <Messages messages={messages} />

          <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              alignItems: "center",
              margin: "10px 0",
            }}
          >
            {/* <label style={{ margin: "0", display: "flex" }}>
              <input
                type="radio"
                value="llama"
                checked={selectedOption === "llama"}
                onChange={handleOptionChange}
              />
              LLama3
            </label>

            <label style={{ margin: "0", display: "flex" }}>
              <input
                type="radio"
                value="mistral"
                checked={selectedOption === "mistral"}
                onChange={handleOptionChange}
              />
              Mistral
            </label> */}
            <label style={{ margin: "0", display: "flex" }}>
              <input
                type="radio"
                value="GPT"
                checked={selectedOption === "GPT"}
                onChange={handleOptionChange}
              />
              Chat-GPT
            </label>
            {/* <label style={{ margin: "0", display: "flex" }}>
              <input
                disabled
                type="radio"
                value="fingpt"
                checked={selectedOption === "fingpt"}
                onChange={handleOptionChange}
              />
              Fin-GPT
            </label> */}
          </div>
          {selectedOption === "imageToText" ? (
            <UploadImage onSend={send} />
          ) : (
            <Input onSend={send} />
          )}
        </div>
        <div
          style={{
            width: "25%",
            // padding: "2%",
            borderRadius: '10px',
            border: "2px solid #002f50",
            display: "flex",
            justifyContent: "space-between",
            alignItems: "flex-start",
            height: "600px",
            flexWrap: "wrap",

            gap: "20px",
          }}
        >
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              flexDirection: "column",
              // gap: "15px",

            }}
          >
            <div style={{ marginBottom: "5px", borderRadius: '8px 8px 0 0', background: '#002f50', width: '100%', color: '#fff' }}>
              <h3 style={{ padding: "0 10px 0 10px" }} >Upload File</h3>
            </div>
            <div
              style={{
                // overflow: "scroll",
                overflowX: "auto",
                height: "100%",
                padding: '10px',
                width: '90%'
                // width: '100%'
              }}
            >
              {/* <div> */}
              {/* <label htmlFor="fileInput" style={{ marginBottom: "10px" }}>
                  Choose File
                </label> */}
              {/* </div> */}
              <input
                type="file"
                id="fileInput"
                onChange={handleFileChange}
                style={{ marginBottom: "10px" }}
              />
              {fileName &&
                fileName.map((x) => (
                  <p key={x} style={{ margin: "5px 0" }}>
                    Selected file: {x}
                  </p>
                ))}
              <button className="Button" onClick={handleUpload}>
                {Loader ? (
                  <CircularProgress color="inherit" size={12} />
                ) : (
                  "Upload"
                )}
              </button>

              <div>
                {files_data?.map((ele, index) => (
                  <div
                    key={index}
                    style={{
                      margin: "10px 0",
                      border: "2px solid gray",
                      borderRadius: "5px",
                      padding: "5px",
                      cursor: "pointer",
                      position: 'relative',
                      background:
                        selectedfile_name === ele.file_name ? "gray" : "white",
                    }}
                  // onClick={() => convert_file_into_vector(ele.file_name)}
                  >
                    <div style={{ display: 'flex', alignItems: 'center' }}
                      onClick={() => convert_file_into_vector(ele.file_name)}
                    >
                      <div style={{ width: '70px', height: '70px' }}>
                        <img style={{ maxWidth: '100%', maxHeight: '100%' }} src="./pdf-icon.png" alt="" />
                      </div>
                      <div>
                        <h4 style={{ marginBottom: "0" }}>{ele.file_name}</h4>
                        <p style={{ marginTop: "0" }}>{ele.file_size}</p>
                      </div>
                    </div>
                    <div style={{ position: 'absolute', top: 0, right: 0, padding: '10px' }}
                    ><DeleteIcon onClick={() => deleteFile(ele.file_name)}
                      /></div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div >
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={1000}
        onClose={handleClose}
        message={message}
      />
    </>
  );
}

export default App;
